import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorSzymon as author } from 'data/authors';

import img from 'img/blog/updates/tags.png';
import video1 from 'video/updates/tags_1.webm';
import video2 from 'video/updates/tags_2.webm';
import styled from 'styled-components';
import VideoModal from 'components/ui/video-modal';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <h3>How to tag your session recordings?</h3>
      <ul>
        <li>Enter a session</li>
        <li>On the right side, you will find the „Add tag” button, click on it. </li>
        <li>Then name your tag and confirm it by pressing „Enter”.</li>
      </ul>

      <p className="mt-5 mb-5">
        <VideoModal src={{ webm: video1 }} />
      </p>

      <h3>How to search for a session tagged?</h3>
      <ul>
        <li>Go to the list of session recordings</li>
        <li>Click on the button „Add filters”</li>
        <li>Press the „+” icon</li>
        <li>From the filters, select „Tags”</li>
        <li>Enter the name of a tag you want to find</li>
        <li>And press „Apply filters”</li>
      </ul>

      <p className="mt-5 mb-5">
        <VideoModal src={{ webm: video2 }} />
      </p>

      <p className="mt-3">
        We hope you like it! Other new improvements are coming soon. If you think something needs
        improvement, or do you have an idea for functionality? Write to us:{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Tag sessions to find them easily',
  url: '/blog/tag-session-to-find-them-easily/',
  description:
    'Now you can mark the recordings with the appropriate tag so that you can quickly return to them later using the filters on the list of records.',
  author,
  img,
  date: '2019-07-15',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial={frontmatter.descriptionSocial || frontmatter.description}
    img={frontmatter.img}
  />
);
